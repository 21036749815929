import React from 'react';
import * as XLSX from 'xlsx'
import { connect } from "react-redux";
import { sendExcel, destroyExcel, updateExcel } from '../modules/trades';
import { logout } from "../modules/auth";
import { createPortal } from "../modules/subscription";

const Excel = props => {
  let option = "update"

  const organizeExcel = async (d, option) => {
    let groupedTrades = []
    let groups = {}

    for (let i=0; i<d.length; i++) {
      let ticker = d[i]["Symbol"]

      groups[ticker] === undefined && (groups[ticker] = {Ticker: ticker, NetProfit: 0, GrossProfit: 0, ShareCount: 0, TimeStamp: 0, Date: "", Commissions: 0, SharesTraded: 0, Side: ""})
      groups[ticker]["NetProfit"] += d[i]["Net Proceeds"]
      groups[ticker]["GrossProfit"] += d[i]["Gross Proceeds"]
      groups[ticker]["ShareCount"] === 0 && (groups[ticker]["TimeStamp"] = d[i]["Exec Time"]) && (groups[ticker]["Date"] = d[i]["T/D"])
      d[i]["Side"] === "B" || d[i]["Side"] === "BC" ? groups[ticker]["ShareCount"] += d[i]["Qty"] : groups[ticker]["ShareCount"] -= d[i]["Qty"]
      groups[ticker]["Commissions"] += (d[i]["Comm"] + d[i]["NSCC"])
      groups[ticker]["SharesTraded"] += d[i]["Qty"]
      groups[ticker]["Side"] = groups[ticker]["Side"] === "" ? d[i]["Side"] : groups[ticker]["Side"];

      if (groups[ticker]["ShareCount"] === 0) {
        groupedTrades.push(groups[ticker])
        delete groups[ticker]
      }
    }
    option === "update" ? updateEntry(groupedTrades) : createEntry(groupedTrades)
  }

  const organizeShortFees = async (d, option) => {
    let sum = 0
    for (let i=0; i<d.length; i++) {
      if (d[i]["Note"].split(" ", 1)[0] === "Pre-Borrow" || d[i]["Note"].split(" ", 1)[0] === "Locate" || d[i]["Note"].split(" ", 1)[0] === "Short") {
        sum -= d[i]["Withdraw"]
        sum += d[i]["Deposit"]
    }}
    option === "update" ? updateEntry(sum) : createEntry(sum)
  }

  const createEntry = async (data) => {
    typeof(data) === "number" ? option = "shortFees" : option = "trades"
    let response = await sendExcel(data, option)
    if (response.status === 200) {
      debugger
      props.setSavedTrades(response.data.excel)
    }
  }

  const updateEntry = async (data) => {
    typeof(data) === "number" ? option = "shortFees" : option = "trades"
    let response = await updateExcel(data, option, props.savedTrades.id)
    if (response.status === 200) {
      props.setSavedTrades(response.data.excel)
    }
  }

  const deleteExcel = () => {
    const runDestroy = async () => {
      let response = await destroyExcel(props.savedTrades.id)
      if (response.status === 200) {
        alert("Charts will clear next time you sign in.")
        props.setSavedTrades(null)
        setTimeout(() => {
          props.setMessage("")
        }, 3000);
      }
    }
    props.savedTrades === null ? alert("No data to delete") : runDestroy()
  }

  const cleanData = (data) => {
    return data.map(row => {
      const cleanedRow = {};
      Object.keys(row).forEach(key => {
        const value = row[key];
        if (typeof value === 'string' && !isNaN(value)) {
          cleanedRow[key] = Number(value); // Convert numeric strings to numbers
        } else {
          cleanedRow[key] = value; // Keep other data as is
        }
      });
      return cleanedRow;
    });
  };

  const readExcel = (file) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: 'buffer' });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      let data = XLSX.utils.sheet_to_json(ws);

      // Clean data before resolving
      data = cleanData(data);

      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  promise.then((d) => {
    switch (true) {
      case d[0]["T/D"] === undefined && props.savedTrades !== null:
        let q1 = window.confirm("Short Fees Detected. Add to or overwrite current DataSet?");
        if (q1 === true) {
          organizeShortFees(d, option);
        } else {
          props.setMessage = "Short Fees not added";
        }
        break;
      case d[0]["T/D"] !== undefined && props.savedTrades !== null:
        let q2 = window.confirm("Trade Summary Detected. Add to current DataSet?");
        if (q2 === true) {
          organizeExcel(d, option);
        } else {
          props.setMessage = "Trade Summary not added";
        }
        break;
      case d[0]["T/D"] !== undefined && props.savedTrades === null:
        organizeExcel(d);
        break;
      default:
        organizeShortFees(d);
      }
    });
  };

  const onLogout = async () => {
    let response = await logout();
    if (response.data.success !== true) {
      alert("SignOut failed unexpectedly")
    } else {
      sessionStorage.clear()
      window.location.href = "/";
    }
  }

  const portalSession = async () => {
    let response = await createPortal()
    if (response.status === 200) {
      window.location.href = response.data;
    } else {
      alert("Unable to navigate to Stripe Billing Management")
    }
  }

  let subscribe;
  if (props.userAttrs !== null && props.userAttrs.role === "subscriber") {
    subscribe = (
      <a href onClick={() => portalSession()}>
        Manage Subscription
      </a>
    )
  } else {
    subscribe = (
      <a href onClick={() => props.paywall === "paywall-up" ? props.setPaywall("paywall") : props.setPaywall("paywall-up")}>
        Subscribe
      </a>
    )
  }
  
  return (
    <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
      <a class="dropdown-item" href>
        <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
        {subscribe}
      </a>
      <a class="dropdown-item" href>
        <i class="fas fa-upload fa-sm fa-fw mr-2 text-gray-400"></i>
        <label>
          <input 
            id="file-uplaod-button"
            type="file" 
            onChange={(e) => {
              const file = e.target.files[0]
              readExcel(file)
            }}
          />
          Upload
        </label>
      </a>
      <a class="dropdown-item" href onClick={() => deleteExcel()}>
        <i class="fas fa-trash fa-sm fa-fw mr-2 text-gray-400"></i>
        Clear Data
      </a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" onClick={() => onLogout()} href="/" data-toggle="modal" data-target="#logoutModal">
        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
        Logout
      </a>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    savedTrades: state.savedTrades,
    savedFees: state.savedFees,
    message: state.message,
    userAttrs: state.userAttrs,
    paywall: state.paywall
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSavedTrades: data => {
      dispatch({ type: "SET_SAVEDTRADES", payload: data });
    },
    setSavedFees: data => {
      dispatch({ type: "SET_SAVEDFEES", payload: data });
    },
    setMessage: string => {
      dispatch({ type: "SET_MESSAGE", payload: string })
    },
    setPaywall: paywall => {
      dispatch({ type: "SET_PAYWALL", payload: paywall })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Excel)