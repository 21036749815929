import React, { useState, useEffect } from 'react';
import { saveStrategy, deleteSetup, getSetups, updateSetup } from "../modules/setup";
import { connect } from "react-redux";
import ItemsCarousel from 'react-items-carousel'
import { Button } from "semantic-ui-react";;

const Setups = (props) => {
  const [message, setMessage] = useState("")
  const [editStrat, setEditStrat] = useState(false)
  const [editInfo, setEditInfo] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [editReqs, setEditReqs] = useState({})
  const [cardsToShow, setCardsToShow] = useState(3);

  const submit = (e) => {
    e.preventDefault();
    let name = e.target.name.value
    let req1 = e.target.req1.value
    let req2 = e.target.req2.value
    let req3 = e.target.req3.value
    let req4 = e.target.req4.value
    let req5 = e.target.req5.value
    let req6 = e.target.req6.value
    let req7 = e.target.req7.value
    let req8 = e.target.req8.value
    let req9 = e.target.req9.value
    let req10 = e.target.req10.value
    
    const saveNew = async () => {
      let response = await saveStrategy(
        name, req1, req2, req3, req4, req5, req6, req7, req8, req9, req10
      )
      if (response.status === 200) {
        setMessage(`${name} added Successfully`)
        clearFields()
        reloadSetups()
      } else {
        setMessage(response.data.errors[0])
      }
    }

    const update = async () => {
      let reqs = {
        name: name, 
        req1: req1, 
        req2: req2, 
        req3: req3, 
        req4: req4, 
        req5: req5, 
        req6: req6, 
        req7: req7, 
        req8: req8, 
        req9: req9, 
        req10: req10
      }
      let response = await updateSetup(editInfo[1], reqs)
      if (response.status === 200) {
        setMessage(`${name} updated successfully`)
        clearFields()
        reloadSetups()
      } else {
        setMessage(response.data.errors[0])
      }
    }
    editStrat === false ? saveNew() : update() 
  }

  const deleteStrat = async (setupId, strategyName) => {
    let response = await deleteSetup(setupId)
    if (response.status === 200) {
      setMessage(`"${strategyName}" Deleted.`)
      reloadSetups()
    } else {
      setMessage(`${response.error}`)
    }
  }

  const reloadSetups = async () => {
    let response = await getSetups()
    if (response !== undefined && response.status === 200) {
      props.setStrategies(response.data)
    } 
    setEditStrat(false)
  }

  const editSetup = async (setupId, stratName) => {
    setEditReqs({})
    let strategy = {}
    for (let i=0; i<props.strategies.length; i++) {
      if (props.strategies[i].name === stratName) {
        strategy = props.strategies[i]
        setEditReqs(strategy)
        break
      }
    }
    setEditStrat(true)
    setEditInfo([stratName, setupId])
  }
  
  const cancleEdit = () => {
    setEditStrat(false)
    setEditInfo([""])
    clearFields()
    reloadSetups()
  }

  const clearFields = () => {
    let num = 1
    let obj = {}
    obj.name = ""
    for (let i=1; i < 11; i++) {
      obj[`req${num}`] = ""
      num+=1
    }
    setEditReqs(obj)
  }

  const clickHandler = () => {
    setEditReqs({})
  }

  let savedStrategies;
  if (props.strategies.length !== undefined) {
    savedStrategies = props.strategies.map(strategy => {
      let preReqArray = []
      let preReqs;
      for (const property in strategy) {
        if (property[0] === "r" && strategy[property] !== "") {
          preReqArray.push(strategy[property])
        }
      }
      preReqs = preReqArray.map(req => {
        return (
          <h6><i class="fas fa-long-arrow-alt-right"></i> {req}</h6>
        )
      })
      return (
        <div>
          <div id={`setup${strategy.id}`} class="col-xl-12 col-lg-12">
            <div class="card shadow mt-4 mb-4">
                {/* <!-- Card Header - Dropdown --> */}
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-success">{strategy.name}</h6>
                    <div class="dropdown no-arrow">
                        <a class="dropdown-toggle" href role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                            <div class="dropdown-header">Manage:</div>
                            <a class="dropdown-item" href onClick={() => deleteStrat(strategy.id, strategy.name)}>Delete</a>
                            <a class="dropdown-item" href id={`edit${strategy.id}`} onClick={() => editSetup(strategy.id, strategy.name)}>Edit</a>
                        </div>
                    </div>
                </div>
                {/* <!-- Card Body --> */}
                <div class="card-body">
                  <h6 class="text-uppercase text-xs">Conditions</h6> 
                  <div class="dropdown-divider">
                  </div>
                  <div class="pt-2 pb-2">
                    {preReqs}
                  </div>
                </div>
            </div>
          </div>
        </div>
      )
    })
  } else {
    savedStrategies = (<h4>No Saved Strategies</h4>)
  }

  useEffect(() => {
    reloadSetups()
  }, [])

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 600) {
        setCardsToShow(1); // Show one card on small screens
      } else if (window.innerWidth < 900) {
        setCardsToShow(2); // Show two cards on medium screens
      } else {
        setCardsToShow(3); // Show three cards on large screens
      }
    };

    updateCardsToShow(); // Run on mount
    window.addEventListener('resize', updateCardsToShow); // Adjust on window resize

    return () => {
      window.removeEventListener('resize', updateCardsToShow); // Cleanup listener
    };
  }, []);
  

  return (
    <>
      <div className="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">Save Your Strategies</h1>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card shadow mb-4">
              <a href="#collapseCardExample" class="d-block card-header py-3 collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseCardExample">
                {editStrat === true ? <h6 class="m-0 font-weight-bold text-warning">Edit Strategy "{editInfo[0]}"</h6> : <h6 class="m-0 font-weight-bold text-verydark">Add New Strategy</h6> }
              </a>
              <div class="collapse" id="collapseCardExample">
                <div class="card-body">
                  {/* Input Form */}
                  <form id="main-form" onSubmit={submit} className="card shadow-sm p-4">
                    <div className="row g-3">
                      <div className="col-md-5 mb-2">
                        <label className="form-label">Strategy Name</label>
                        <input
                          required
                          type="text"
                          name="name"
                          id="name"
                          value = {editReqs.name}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req1"
                          id="req1"
                          value = {editReqs.req1}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req2"
                          id="req2"
                          value = {editReqs.req2}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="tex"
                          name="req3"
                          id="req3"
                          value = {editReqs.req3}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req4"
                          id="req4"
                          value = {editReqs.req4}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req5"
                          id="req5"
                          value = {editReqs.req5}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req6"
                          id="req6"
                          value = {editReqs.req6}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req7"
                          id="req7"
                          value = {editReqs.req7}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req8"
                          id="req8"
                          value = {editReqs.req8}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req9"
                          id="req9"
                          value = {editReqs.req9}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Condition</label>
                        <input
                          type="text"
                          name="req10"
                          id="req10"
                          value = {editReqs.req10}
                          onClick= {clickHandler}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="border-left-success mt-3 p-2">
                      <h6 class="ml-2" id='result-message'>{message}</h6>
                    </div>
                    {/* Submit Button */}
                    <div className="text-center mt-4">
                    {editStrat === false ? 
                      <button id="create" className="btn btn-primary">Create Strategy</button> :
                      <button className="btn btn-primary">Save Edit</button> }
                    </div>
                  </form>
                  { editStrat === true && 
                  <button id='cancle-eidt'
                    onClick={() => cancleEdit()}
                  >Cancel</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-header border-left-warning ml-1"> {savedStrategies.length} Entries Saved:</div>

        {editStrat === false &&
        <div>
          <div>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={cardsToShow}
              gutter={10}
              leftChevron={
                <Button circular icon='angle left' />
              }
              rightChevron={
                <Button circular icon='angle right'/>
              }
              chevronWidth={40}
            >
              {savedStrategies}
            </ItemsCarousel>
          </div>
        </div>
        }
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    strategies: state.strategies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStrategies: array => {
      dispatch({ type: "SET_STRATEGIES", payload: array });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setups);