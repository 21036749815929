import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

const Plays = props => {
  const [tickers, setTickers] = useState(null)

  const getTickers = () => {
    let storage = JSON.parse(sessionStorage.getItem('tickers'))
    setTickers(storage.reverse())
  }

  const deleteItem = (e) => {
    let tickers = []
    let storage = JSON.parse(sessionStorage.getItem('tickers'))
    storage.forEach(item => {
      if (item[0] !== e) {
        tickers.push(item)
      }
    })
    sessionStorage.setItem('tickers', JSON.stringify(tickers))
    getTickers()
  }

  useEffect(() => {
    if (props.count > 0) {
      getTickers()
    }
  }, [props.count, props.message])

  return (
    <>
      <h2>{props.message}</h2>
      <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Entry and Sizing Details</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length" id="dataTable_length">
                    <label>Show 
                      <select name="dataTable_length" aria-controls="dataTable" class="custom-select custom-select-sm form-control form-control-sm">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select> entries
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div id="dataTable_filter" class="dataTables_filter">
                    <label>Search:<input type="search" class="form-control form-control-sm" value="Disabled " placeholder="" aria-controls="dataTable"/>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <table class="table table-bordered dataTable" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting sorting_asc" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending">Ticker</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">Entries</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">Shares</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Age: activate to sort column ascending">Targets (1, 2, 3 : 1R)</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Start date: activate to sort column ascending">Stop Price</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Salary: activate to sort column ascending">Strategy</th>
                          <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Salary: activate to sort column ascending">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                    { tickers !== null && (
                      tickers.map(ticker => {
                        let name = ticker[1].inputs["ticker"]
                        let entry = ticker[1].inputs["stockPrice"]
                        return (
                        <>
                          <tr class="odd">
                            <td class="sorting_1">${name.toUpperCase()}</td>
                            <td>$ {entry}</td>
                            <td>{ticker[1].shares}</td>
                            <td>{ticker[1].tradeParams["pts"][0]}, {ticker[1].tradeParams["pts"][1]}, {ticker[1].tradeParams["pts"][2]}</td>
                            <td>$ {ticker[1].tradeParams["stopPrice"]}</td>
                            <td>{ticker[1].setup}</td>
                            <td onClick={() => deleteItem(ticker[0])} id="delete" className="ticker" style={{cursor:"pointer"}}>X</td>
                          </tr>
                        </>
                        )
                      })
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  {tickers !== null && <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of {tickers.length} entries</div>}                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    count: state.count,
    message: state.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCount: data => {
      dispatch({ type: "SET_COUNT", payload: data });
    },
    setMessage: string => {
      dispatch({ type: "SET_MESSAGE", payload: string })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plays);