import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Line, Bar } from 'react-chartjs-2';
import CommissionsChart from "./CommissionsChart";
import DayOfWeekCharts from "./DayOfWeekCharts";
import HourlyChart from "./HourlyChart";
import WinCurve from "./WinCurve";
import ProfitLoss from "./ProfitLoss";
import { getTrades } from "../modules/trades";
import { Icon } from 'semantic-ui-react';

const ProfitChart = props => {
  const [profit, setProfit] = useState([])
  const [date, setDate] = useState([])
  const [commissionsTotal, setCommissionsTotal] = useState(0)
  const [barData, setBarData] = useState(null)
  const [timeSegments, setTimeSegments] = useState(null)
  const [grossNet, setGrossNet] = useState("GrossProfit")
  const [winPercentages, setWinPercentages] = useState([])
  const [profitLoss, setProfitLoss] = useState([])
  const [profitChart, setProfitChart] = useState("line")
  const [numericalDate, setNumericalDate] = useState([]);

  let dailyPreformance = {
    Monday: [0,[]],
    Tuesday: [0,[]],
    Wednesday: [0,[]],
    Thursday: [0,[]],
    Friday: [0,[]]
  }

  let timeBlocks = {}
  const buildIntervals = () => {
    let num = 0.33333
    for (let i=0; i < 9; i++) {
      timeBlocks[`${Number(num.toFixed(5))}`] = [0, 0, 0, 0]
      num+=0.04167
    }
  }

  let dates = []
  let commissions = 0
  const buildDateArray = () => {
    for (let i=0; i<props.savedTrades.data.length; i++) {
      let date = props.savedTrades.data[i]["Date"]
      !dates.includes(date) && dates.push(date)
      commissions += (props.savedTrades.data[i]["Commissions"])
    }
    setCommissionsTotal(commissions)
  }

  const setData = () => {
    buildIntervals()
    buildDateArray()
    
    let stats = {
      wins: 0,
      loss: 0,
      gains: 0,
      negGains: 0,
      largestWin: 0,
      largestLoss: 0
    }
    
    let dailyProfits = 0
    let cumulativeGains = []
    let winData = []
    let profitData = []
    let updatedDates =[]
    dates.map(date => {
      let total = 0
      for(let i=0; i<props.savedTrades.data.length; i++) {
        switch (true) {
          case props.savedTrades.data[i]["Date"] === date && props.savedTrades.data[i][grossNet] > 0:
            stats['gains']+=props.savedTrades.data[i][grossNet]
            stats['wins']++
            for(let int in timeBlocks) {
              ((Number(int) <= props.savedTrades.data[i]["TimeStamp"]) && (props.savedTrades.data[i]["TimeStamp"] < Number(int)+0.04167)) && (timeBlocks[int][0] += props.savedTrades.data[i][grossNet]) && (timeBlocks[int][1]++)
            }
            total += props.savedTrades.data[i][grossNet]
            if (props.savedTrades.data[i][grossNet] > stats['largestWin']) stats['largestWin'] = props.savedTrades.data[i][grossNet]
            break;
          case props.savedTrades.data[i]["Date"] === date && props.savedTrades.data[i][grossNet] <= 0:
            stats['negGains']+=props.savedTrades.data[i][grossNet]
            stats['loss']++

            for(let int in timeBlocks) {
              ((Number(int) <= props.savedTrades.data[i]["TimeStamp"]) && (props.savedTrades.data[i]["TimeStamp"] < Number(int)+0.04167)) && (timeBlocks[int][2] += props.savedTrades.data[i][grossNet]) && (timeBlocks[int][3]++)
            }
            total += props.savedTrades.data[i][grossNet]
            if (props.savedTrades.data[i][grossNet] < stats['largestLoss']) stats['largestLoss'] = props.savedTrades.data[i][grossNet]
            break;
            default:
        break;
        }
      }
      
      profitData.push(((stats['gains']/stats['wins'])/((stats['negGains']/stats['loss'])*-1)).toFixed(2))
      winData.push(((stats['wins']/(stats['loss']+stats['wins']))*100).toFixed(2))
      dailyProfits += total
      cumulativeGains.push(dailyProfits.toFixed(2))

      //Change date formats
      let excelDate = date;
      let epoch = new Date(1900, 0, 1); // January 1, 1900
      const actualDate = new Date(epoch.getTime() + (excelDate - 2) * 86400000);
      
      //Day of the Week
      const options = { weekday: 'long' }; // Get full day name (e.g., Thursday)
      const dayOfWeek = actualDate.toLocaleDateString('en-US', options); // Extract day name

      //Short numerical Date
      const year = actualDate.getFullYear(); // Get year
      const month = String(actualDate.getMonth() + 1).padStart(2, '0'); // Get zero-padded month
      const day = String(actualDate.getDate()).padStart(2, '0'); // Get zero-padded day

      const formattedDate = `${year}/${month}/${day}`; // Format date
      updatedDates.push(formattedDate); // Push formatted date to array
      
      for (let property in dailyPreformance) {
        dayOfWeek === property && dailyPreformance[dayOfWeek][1].push(total) && dailyPreformance[dayOfWeek][0]++
      }
    })
    setNumericalDate(updatedDates);
    props.setStats(stats)
    setWinPercentages(winData)
    setProfitLoss(profitData)
    setDate(dates)
    setProfit(cumulativeGains)
    setBarData(dailyPreformance)
    setTimeSegments(timeBlocks) 
  }
  
  const lineData = {
    labels: numericalDate,
    datasets: [
      {
        label: "",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "rgba(13, 68, 105, 0.91)",
        borderColor: "rgba(12, 66, 102, 0.91)",
        pointRadius: 0,
        pointBackgroundColor: "rgba(78, 115, 223, 1)",
        pointBorderColor: "rgba(78, 115, 223, 1)",
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
        pointHoverBorderColor: "rgba(78, 115, 223, 1)",
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: profit,
      }
    ]
  };

  const lineOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 20
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          // Include a dollar sign in the ticks
          callback: function(value, index, values) {
            return '$' + Intl.NumberFormat().format(value);
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    }
  }

  let winRate = 0
  const calulateWinRate = () => {
    if (props.savedTrades !== null && props.stats !== null) {
      winRate = ((props.stats['wins'] / (props.stats['wins'] + props.stats['loss'])) * 100).toFixed(2)
    } else {
      winRate = 0
    }
  }
  calulateWinRate()

  useEffect(() => {
    const indexExcels = async () => {
      let response = await getTrades()
      if (response.data !== null) {
        props.setSavedTrades(response.data.excels[0])
      }
    }
    indexExcels()

    if (sessionStorage.getItem('user') !== null && props.userAttrs === null) {
      let user = JSON.parse(sessionStorage.getItem('user'))
      props.setUser(user)
    }
  }, [])

  useEffect(() => {
    if (props.savedTrades !== null) {
      setData()
    }
  }, [props.savedTrades, grossNet])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container-fluid"> 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {props.userAttrs !== null && <h1 className='h3 mb-0 text-grey-800'>
          Welcome, {props.userAttrs.nickname}
        </h1>}
      </div>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body"> 
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    <h3>Trade Count</h3> 
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {props.savedTrades !== null && props.stats !== null ?
                    <h3>{props.savedTrades.data.length}</h3> : <h3>No Data</h3>
                    }
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-calendar fa-2x text-verydark"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                        <h3>Win %</h3>
                        </div>
                        <div class="row no-gutters align-items-center">
                          <div class="col-auto">
                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                            <h3>{winRate}%</h3>
                            </div>
                          </div>
                          <div class="col">
                            <div class="progress progress-sm mr-2">
                                <div class="progress-bar bg-info" role="progressbar" style={{ 
                                  width:  `${winRate}%`
                                  }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-trophy fa-2x text-verydark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                          <h3>{grossNet.substring(0, grossNet.indexOf("P"))} Profits</h3>
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                        {props.savedTrades !== null && props.stats !== null ?
                          <h3>${((props.stats['gains'])+(props.stats['negGains'])).toFixed(2)}</h3> : <h3>No Data</h3>
                        }
                        </div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-dollar-sign fa-2x text-verydark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-warning shadow h-100 py-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          <h3>Avg Win : Loss</h3>
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                          {props.savedTrades !== null && props.stats !== null ?
                            <h3>{((props.stats['gains']/props.stats['wins'])/((props.stats['negGains']/props.stats['loss'])*-1)).toFixed(2)} : 1</h3> : <h3>No Data</h3>
                          }
                        </div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-check fa-2x text-verydark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row align-items-stretch">
        {/* <!-- Area Chart --> */}
        <div class="col-xl-8 col-lg-7">
          <div class="card shadow mb-4 card-profit-curve">
            {/* <!-- Card Header - Dropdown --> */}
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h6 class="m-0 font-weight-bold text-verydark">{grossNet.substring(0, grossNet.indexOf("P"))} Profit Curve</h6>
                <div className="equityCurveButtons">
                  <div className={profitChart === "line" && "equityButtonActive"} onClick={() => setProfitChart("line")}>
                    <Icon name='line graph' />
                  </div>
                  <div className={profitChart === "bar" && "equityButtonActive"} onClick={() => setProfitChart("bar")}>
                    <Icon name='chart bar outline' />
                  </div>
                </div>
              </div>
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle text-verydark" href="0" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Gross or Net Values<i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                  <div class="dropdown-header">Select:</div>
                  <a class="dropdown-item" href onClick={(event) => {
                    event.preventDefault();
                    setGrossNet("GrossProfit");
                  }}>Gross</a>
                  <a class="dropdown-item" href onClick={(event) => {
                    event.preventDefault();
                    setGrossNet("NetProfit");
                  }}>Net</a>
                </div>
              </div>
            </div>
            {/* <!-- Card Body --> */}
            <div class="card-body">
              <div class="chart-area">
              {profitChart === "line" ? (
                <Line 
                  data = {lineData}
                  options = {lineOptions}
                />
              ) : (
                <Bar 
                  data = {lineData}
                  options = {lineOptions}
                />
              )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Pie Chart --> */}
        <div class="col-xl-4 col-lg-5">
          <div class="card shadow mb-4 card-win-curve">
            {/* <!-- Card Header - Dropdown --> */}
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-verydark">Win %</h6>
            </div>
              {/* <!-- Card Body --> */}
            <div class="card-body">
                <WinCurve date={numericalDate} winPercentages={winPercentages}/>
            </div>
          </div>
          <div class="card shadow mb-4">
            {/* <!-- Card Header - Dropdown --> */}
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-verydark">R:R Ratio</h6>
            </div>
              {/* <!-- Card Body --> */}
            <div class="card-body">
                <ProfitLoss date={numericalDate} profitLoss={profitLoss}/>
            </div>
          </div>
        </div>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h4 className='h5 mb-0 text-grey-800'>
          Trading Fees
        </h4>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-verydark">PnL / Commissions / Locate Fees</h6>
              </div>
              <div class="card-body">
                  <CommissionsChart commissions={commissionsTotal} netProfit={profit} grossNet={grossNet.substring(0, grossNet.indexOf("P"))} />
                  <div class="mt-4 text-center medium">
                      <span class="mr-3">
                          <i class="fas fa-circle text-primary"></i> PnL
                      </span>
                      <span class="mr-3">
                          <i class="fas fa-circle text-success"></i> Fees
                      </span>
                      <span class="mr-3">
                          <i class="fas fa-circle text-info"></i> Locate Fees
                      </span>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h4 className='h5 mb-0 text-grey-800'>
          Breakdown by Day of the Week
        </h4>
      </div>
      <div>
        <DayOfWeekCharts barData={barData} />
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h4 className='h5 mb-0 text-verydark'>
          Breakdown by Hour
        </h4>
      </div>
      <div>
        <HourlyChart times={timeSegments} grossNet={grossNet.substring(0, grossNet.indexOf("P"))}/>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    savedTrades: state.savedTrades,
    message: state.message,
    userAttrs: state.userAttrs,
    stats: state.stats
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSavedTrades: data => {
      dispatch({ type: "SET_SAVEDTRADES", payload: data });
    },
    setMessage: string => {
      dispatch({ type: "SET_MESSAGE", payload: string });
    },
    setStats: string => {
      dispatch({ type: "SET_STATS", payload: string });
    },
    setUser: data => {
      dispatch({ type: "SET_USER", payload: data });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitChart)