import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createPortal } from "../modules/subscription";
import { showUser } from "../modules/auth";

const Success = () => {
  const [user, setUser] = useState({})
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [params, setParams] = useState(false)

  const portalSession = async (e) => {
    e.preventDefault();
    let response = await createPortal(e.target.session_id.value)
    if (response.status === 200) {
      window.location.href = response.data;
    } else {
      alert("Unable to navigate to Stripe Billing Management")
    }
  }

  let date = Date().slice(0, 15)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.get('success') && query.get('session_id')) {
      setSessionId(query.get('session_id'))
      setSuccess(true)
      setParams(true)
    }
    if (query.get('canceled')) {
      setSuccess(false)
      setParams(true)
    }

    const checkUserStatus = async (session_id) => {
      let response = await showUser(session_id)
      if (response.status === 200) {
        setUser(response.data)
      } else {
        console.log("No user logged into server")
      }
    }
    checkUserStatus(`ses=${query.get('session_id')}`)
  }, []);

  return (
    <div class="container-fluid">
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <nav class="navbar navbar-expand navbar-light static-top">
            <div className=' d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100'>
              <div class="input-group">
                <div class="input-group-append">
                  {/* left side of nav bar here */}
                  <div style={{width: "200px"}}>
                    <a href="/"><img src="/TradeLogs.png"  alt="TradeLogs Logo"/></a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="success-page">
        {params ? (
          <>
            {success ? (
              <>
                <h1 className="light-blue"><i className="check circle icon"></i>SUCCESS</h1>
                <h6>Your TradeLogs subscription has successfully registered.</h6>
                <form id="create-portal-session" onSubmit={portalSession}>
                  <input
                    type="hidden"
                    id="session-id"
                    name="session_id"
                    value={sessionId}
                  />
                  <button className="btn btn-primary mt-2" id="checkout-and-portal-button" type="submit">
                    Manage Subscription
                  </button>
                </form>
              </> 
            ) : <h1 className="red"><i className="close icon"></i>CANCELED</h1>}
            <div class="card-body">
          <div class="table-responsive">
            <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
              <div class="row">
                <div class="col-sm-12">
                  <table class="table table-bordered dataTable" id="dataTable" width="90%" cellspacing="0" role="grid" aria-describedby="dataTable_info">
                    <thead>
                        <tr role="row">
                          <th class="bg-gray-100" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending">Description</th>
                          <th class="bg-gray-200" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr class="odd">
                        <td>Customer Email</td>
                        <td>{user !== null ? <>{user.email}</> : <>N/A</>}</td>
                      </tr>
                      <tr class="odd">
                        <td>Date</td>
                        <td>{date}</td>
                      </tr>
                      <tr class="odd">
                        <td>Transaction Status</td>
                        <td>{success ? <span className="right-align green">Success</span> :  <span className="right-align red">Canceled</span>}</td>
                      </tr>
                      <tr class="odd">
                        <td>Payment Amount</td>
                        <td>Monthly</td>
                      </tr>
                      <tr class="odd">
                        <td>Subscription Period</td>
                        <td>$12.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
          </>
        ) : ( 
          <h2 className="four-o-four"><span className="red">404.</span> Nothing to see here!</h2>
        )}
        <p><Link to='/panes' >&larr; back to TradeLogs dashboard</Link></p>
      </div>
    </div>
  )
}

export default Success;