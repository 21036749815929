import React from 'react';
import { Line } from 'react-chartjs-2';

const ProfitLoss = props => {

  const lineData = {
    labels: props.date,
    datasets: [
      {
        label: '',
        fill: false,
        lineTension: 0.3,
        borderColor: "darkred",
        pointRadius: 0,
        pointBackgroundColor: "darkred",
        pointBorderColor: "darkred",
        pointHoverRadius: 3,
        pointHoverBackgroundColor: "darkred",
        pointHoverBorderColor: "darkred",
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: props.profitLoss
      }
    ]
  };

  const lineOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 10
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          // Include a dollar sign in the ticks
          callback: function(value, index, values) {
            return '' + Intl.NumberFormat().format(value);
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    }
  }

  return (
    <div>
      <Line 
        data = {lineData}
        options = {lineOptions}
      />
    </div>
  )
};

export default ProfitLoss;