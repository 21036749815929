import React from 'react';

const HowTo = () => {

  return (
    <>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <nav class="navbar navbar-expand navbar-light static-top mx-4">
            <div className='d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100'>
              <div class="input-group">
                <div class="input-group-append">
                  {/* left side of nav bar here */}
                  <div style={{width: "30%", minWidth: "150px", marginLeft: "-2em"}}>
                    <a href="/"><img src="/TradeLogs.png"  alt="TradeLogs Logo"/></a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Topbar Navbar --> */}
            <ul class="navbar-nav ml-auto">
              <li class="nav-item mx-2">
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <section className='container terms' style={{paddingTop: 0}}>
        <h4 className='text-gray-800'>How To</h4>
        <h5 className='text-gray-800'>Get Started</h5>
        <p>Register account and log in</p>

        <h5 className='text-gray-800'>Dashboard</h5>
        <p>Welcome to TradeLogs! Once logged in, familiarize yourself with the dashboard and the various section tabs.</p>
        <p>Clicking the TradeLogs Logo at the top left of the page will take you back to the Home page, logging you out.</p>

        <h5 className='text-gray-800'>Upload Trades</h5>
        <p>Currently Supported Brokers: TradeZero </p>
        <p>1. Log in to your TradeZero account</p>
        <p>2. Head over to Account Overview</p>
        <img src="../images/TZAcountOverview.png" alt="Acount Overview" />
        <br />
        <p>3. Scroll down and select the heading "Trades".</p>
        <img src="../images/TZTrades.png" alt="Trades overview" />
        <br />
        <p>4. Select the desired date range.</p>
        <img src="../images/TZDates.png" alt="dates Overview" />
        <br />
        <p>5. Click the Excel symbol to download the file.</p>
        <img src="../images/TZDownload.png" alt="Download example" />
        <br />
        <p>6. Open the file, select the file dropdown and select 'Save as'.</p>
        <img src="../images/ExcelSaveAs.png" alt="File Save As" />
        <br />
        <p>7. Re-save as "Excel Workbook (.xlsx)".</p>
        <img src="../images/ExcelXlsx.png" alt="File Save As .xlsx" />
        <br />
        <p>8. Return to www.trade-logs.com, Sign in, and upload the newly saved .xlsx file.</p>
        <img src="../images/Uploadfile.png" alt="Tradelogs upload" />
        <br />
        <h3>Upload Software and Locate Fees</h3>
        <p>Repeat steps for Upload Trades. Except go to your "Cash Journal" tab instead of your "Trades" tab.</p>
        <img src="../images/TZCashJournal.png" alt="Cash Journal" />
      </section>
      <footer class="sticky-footer bg-white mt-4">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright © TradeLogs 2025. All rights reserved.</span>
            </div>
          </div>
      </footer>
    </>
  )
}

export default HowTo;