import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { connect } from "react-redux";

const CommissionsChart = props => {
  let grossProfitFees = [0, 0]
  if (props.savedTrades !== null) {
    grossProfitFees[0] = props.netProfit[props.netProfit.length - 1]
    grossProfitFees[1] = props.savedTrades['fees']
  }
  
  const pieData = {
    labels: [`${props.grossNet} PnL`, "Commissions", "Locate Fees"],
    datasets: [
      {
        backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc'],
        hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf'],
        hoverBorderColor: "rgba(234, 236, 244, 1)",
        data: [grossProfitFees[0], (props.commissions*-1), Number(grossProfitFees[1]).toFixed(2)]
      }
    ]
  };

  const pieData2 = {
    labels: [`${props.grossNet} PnL`, "Total Fees"],
    datasets: [
      {
        backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc'],
        hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf'],
        hoverBorderColor: "rgba(234, 236, 244, 1)",
        data: [grossProfitFees[0], Number((props.commissions*-1) + grossProfitFees[1]).toFixed(2)]
      }
    ]
  };
  
  const pieOptions = {

    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
    },
    legend: {
      display: false
    },
    cutoutPercentage: 70,
  }


  return (
    <div className="row">
      <div class="col-xl-6 col-lg-6"> 
        <Doughnut
          data = {pieData}
          options = {pieOptions}
        />
      </div>
      <div class="col-xl-6 col-lg-6"> 
        <Doughnut
          data = {pieData2}
          options = {pieOptions}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    savedTrades: state.savedTrades
  };
};

export default connect(mapStateToProps)(CommissionsChart)