import React, { useEffect, useState } from "react";
import Pannel from './Pannel'
import ProfitChart from "./ProfitChart"
import GapStats from "./GapStats"
import Setups from "./Setups"
import Excel from "./Excel";
import PayWall from "./PayWall";
import Calculator from "./Calculator";
import { showUser } from "../modules/auth";
import { connect } from "react-redux";
import { getSetups } from "../modules/setup";
import Trades from "./Trades";

const Panes = props => {
  const [activePane, setActivePane] = useState("Overview");
  const [toggled, setToggled] = useState(true);

  const paneContent = {
    Overview: { icon: <i className="fas fa-chart-line"></i>, content: <ProfitChart /> },
    Calculator: { icon: <i className="fas fa-calculator"></i>, content: <Calculator /> },
    Strategies: { icon: <i className="fas fa-lightbulb"></i>, content: <Setups /> },
    "Gap Stats": { icon: <i className="fas fa-chart-bar"></i>, content: <GapStats /> },
    "Trade History": { icon: <i className="fas fa-history"></i>, content: <Trades /> },
  };
  

  useEffect(() => {
    if ( props.userAttrs === null ) {
      let user = JSON.parse(sessionStorage.getItem('user'))

      const checkUserStatus = async (user_id) => {
        let response = await showUser(user_id)
        if (response.status === 200) {
          props.setUser(response.data)
        } else {
          console.log("No user logged into server")
        }
      }
      checkUserStatus(user.id)
    }

    const loadSetups = async () => {
      let response = await getSetups()
      if (response !== undefined && response.status === 200) {
        props.setStrategies(response.data)
      } 
    }
    loadSetups()
  }, [])

  const toggleHandler = () => {
    setToggled(!toggled)
  };

  return (
    <div id="wrapper">
      <ul className={`navbar-nav bg-verydark sidebar sidebar-dark accordion ${toggled ? "toggled" : ""}`} id="accordionSidebar">
        {/* <!-- Sidebar - Brand --> */}
        <a class="sidebar-brand d-flex align-items-center" href="/"><img src="/TradeLogs.png"  alt="TradeLogs Logo"/></a>
        {/* <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div class="sidebar-brand-icon rotate-n-15">
                <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
        </a> */}

        {/* <!-- Divider --> */}
        <hr class="sidebar-divider my-0"/>
        {/* <!-- Nav Item - Dashboard --> */}
        <li class="nav-item active">
          <a class="nav-link" href>
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span  onClick={() => setActivePane("Overview")}>Dashboard</span></a>
        </li>

        {/* <!-- Divider --> */}
        <hr class="sidebar-divider"/>

        {/* <!-- Heading --> */}
        <div class="sidebar-heading">
          Components
        </div>
        {/* Pane Buttons */}
        {Object.keys(paneContent)
        .filter((pane) => pane !== "Overview") // Exclude "Overview"
        .map((pane) => (
          <li
            key={pane}
            className={`nav-item ${activePane === pane ? "active" : ""}`}
            onClick={() => setActivePane(pane)}
          >
            <a className="nav-link" href="#">
              <span>{paneContent[pane].icon}</span>
              <span>{pane}</span>
            </a>
          </li>
        ))}
        {/* <!-- Divider --> */}
        <hr class="sidebar-divider d-none d-md-block"/>
        <div class="sidebar-heading">
            Addons
        </div>
        <li class="nav-item">
          <a class="nav-link collapsed" href data-toggle="collapse" data-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
            <i class="fas fa-fw fa-star"></i>
            <span>Quick Stats</span>
          </a>
          <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
              <Pannel/>
            </div>
          </div>
        </li>

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div class="text-center d-none d-md-inline">
            <button onClick={toggleHandler} class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

        {/* <!-- Sidebar Message --> */}
        <div class="sidebar-card d-none d-lg-flex">
            <img class="sidebar-card-illustration mb-4" src="/images/science.svg" alt="..." />
            <p class="text-center mb-2"><strong class="text-gray-200"     style={{ fontSize: '1rem', fontWeight: 'bold' }}
            >Test Version</strong> Let me know if you experience any broken functions or errors</p>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <PayWall />
          {/* <Pannel/> */}
          <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" onClick={toggleHandler} class="btn btn-link d-md-none rounded-circle mr-3">
              <i class="fa fa-bars"></i>
            </button>
            <div class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
              <div class="input-group">
                <div class="input-group-append">
                  {/* left side of nav bar here */}
                </div>
              </div>
            </div>
            {/* <!-- Topbar Navbar --> */}
            <ul class="navbar-nav ml-auto">
              <div class="topbar-divider d-none d-sm-block"></div>
              {/* <!-- Nav Item - User Information --> */}
              <li class="nav-item dropdown no-arrow">
                {props.userAttrs ? (
                  <a className="nav-link dropdown-toggle"
                    href
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                      {props.userAttrs.nickname || "Guest"}
                    </span>
                    <img
                      className="img-profile rounded-circle"
                      src="/images/Male_profile.svg"
                      alt="..."
                    />
                  </a>
                ) : (
                  <span>Loading...</span>
                )}
                {/* <!-- Dropdown - User Information --> */}
                <Excel/>
              </li>
            </ul>
          </nav>
          <div id="content">{paneContent[activePane].content}</div>
        </div>
      {/* Footer */}
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright © TradeLogs 2024. All rights reserved.</span>
          </div>
        </div>
      </footer>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userAttrs: state.userAttrs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: data => {
      dispatch({ type: "SET_USER", payload: data });
    },
    setStrategies: array => {
      dispatch({ type: "SET_STRATEGIES", payload: array });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Panes);