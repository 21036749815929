import React from 'react';
import { Bar } from 'react-chartjs-2';
import DayOfWeekTotal from './DayOfWeekTotal';

const DayOfWeekCharts = props => {

  let dayGain = []
  let pLAverage = []
  let dayOfWeekTotals = {}
  if (props.barData !== null) {
    let posDays = []
    let negDays = []
    for (let day in props.barData) {
      let avg = props.barData[day][1].reduce((a, b) => a + b, 0)
      pLAverage.push(avg/props.barData[day][1].length)
      dayOfWeekTotals[day]= Number(avg.toFixed(2))
      
      let greenDay = 0
      let redDay = 0
      let redCount = 0
      let greenCount = 0
      props.barData[day][1].forEach(index => {
        index < 0 ? (redDay += index) && redCount++ : (greenDay += index) && greenCount++
      })
      posDays.push(greenDay/greenCount)
      negDays.push((redDay/redCount)*-1)
    }
    dayGain = [posDays, negDays]
  }

  const colours = pLAverage.map((value) => value < 0 ? 'rgba(233, 133, 93, 0.719)' : 'rgba(75,192,192,0.4)');

  const barData = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    datasets: [
      {
        label: 'Daily Avg',
        fill: true,
        backgroundColor: colours,
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: pLAverage
      }
    ]
  };

  const barOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'month'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 6
        },
        maxBarThickness: 25,
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return '$' + new Intl.NumberFormat().format(value);
          },
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
      callbacks: {
        label: function (tooltipItem, chart) {
          var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + ': $' + new Intl.NumberFormat().format(tooltipItem.yLabel);
        },
      }
    },
  }

  const barTwoData = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    datasets: [
      {
        label: 'Average Winning Day',
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: dayGain[0]
      },
      {
        label: 'Average Losing Day',
        fill: false,
        backgroundColor: 'rgba(233, 133, 93, 0.719)',
        borderColor: '#71B37C',
        hoverBackgroundColor: 'rgba(233, 133, 93)',
        data: dayGain[1]
      }
    ]
  };

  return (
    <div class="row">
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">PnL Averages</h6>
            </div>
            <div class="card-body">
                <div>
                  <Bar
                    data = {barData}
                    options = {barOptions}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> Daily Average
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Average Winning Day / Average Losing Day</h6>
            </div>
            <div class="card-body">
                <div>
                <Bar
                  data = {barTwoData}
                  options = {barOptions}
                />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> Winning Day
                  </span>
                  <span class="mr-3">
                      <i class="fas fa-circle red"></i> Losing Day
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Cumulative PnL</h6>
            </div>
            <div class="card-body">
                <div>
                  <DayOfWeekTotal barOptions={barOptions} dayOfWeekTotals={dayOfWeekTotals}  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> Gains
                  </span>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default DayOfWeekCharts