import React, { useState } from "react";
import { connect } from "react-redux";
import { updateRisk } from "../modules/auth";
import { Dimmer, Loader } from 'semantic-ui-react'
import SetUp from "./SetUp";
import Plays from "./Plays"

const Calculator = props => {
  const [riskBp, setRiskBp] = useState([0, 0])
  const [loader, setLoader] = useState(false)
  const [riskValue, setRiskValue] = useState(null)

  const setRisk = async (e) => {
    e.preventDefault();
    setLoader(true)
    let risk = e.target.risk.value / 100
    let response = await updateRisk(props.userAttrs.id, risk);
    if (response.status === 200) {
      setLoader(false)
      props.setUser(response.data)
      setRiskValue("")
    } else {
      setLoader(false)
      alert("Update Failed")
      setRiskValue("")
    }
  }

  const clickHandler = () => {
    setRiskValue(null)
  }

  const onChangeHandler = (e) => {
    let riskValue = e.target.value * props.userAttrs.risk
    let buyPower = e.target.value * 4
    setRiskBp([riskValue, buyPower])
  }

  const submit = (e) => {
    e.preventDefault();
    if (props.preReq === props.checkList.length) {
      const inputs = {
        stop: Number((e.target.price.value - e.target.stop.value).toFixed(2)),
        stockPrice: Number(e.target.price.value),
        ticker: e.target.ticker.value
      }
      const tradeParams = {
        maxShares: Math.floor(riskBp[0] / inputs["stop"]),
        bpMaxShares: Math.floor(riskBp[1] / inputs["stockPrice"]),
        pts: [(inputs["stockPrice"] + inputs["stop"]).toFixed(2), (inputs["stockPrice"] + inputs["stop"]*2).toFixed(2), (inputs["stockPrice"] + inputs["stop"] * 3).toFixed(2)],
        stopPrice: inputs["stockPrice"] - inputs["stop"]
      }
      let answer;
      tradeParams["bpMaxShares"] < tradeParams["maxShares"] ? answer = tradeParams["bpMaxShares"] : answer = tradeParams["maxShares"]
      sendStorage(inputs, tradeParams, answer)
    } else {
      alert("Trade doesn't meet requirements!!!")
    }
  }

  const sendStorage = (inputs, tradeParams, answer) => {
    const trade = {
      inputs,
      tradeParams,
      shares: answer,
      setup: props.setUp,
      date: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    }
    let tickers = []
    if (sessionStorage.getItem('tickers')) {
      tickers = JSON.parse(sessionStorage.getItem('tickers'))
    }
    tickers.push([Math.floor(Math.random() * 1000), trade])
    sessionStorage.setItem('tickers', JSON.stringify(tickers))
    props.setCount(props.count + 1)
  }
  
  return (
  <>
    <div className="container-fluid" id="graphs">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Position Sizing</h1>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card shadow mb-4">
              <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-verydark">Calculator</h6>
              </div>
              <div class="card-body">
          {/* Input Form */}
          <form id="main-form" onSubmit={submit} className="card shadow-sm p-4">
          <div className="row g-3">
            <div className="col-md-6">
              <label htmlFor="equity" className="form-label">Equity</label>
              <input
                required
                type="text"
                placeholder="$"
                name="equity"
                id="equity"
                className="form-control"
                onChange={onChangeHandler}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="ticker" className="form-label">$Ticker</label>
              <input
                required
                type="text"
                name="ticker"
                id="ticker"
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="price" className="form-label">Entry Price</label>
              <input
                required
                type="text"
                placeholder="$"
                name="price"
                id="price"
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="stop" className="form-label">Stop Price</label>
              <input
                required
                type="text"
                placeholder="$"
                name="stop"
                id="stop"
                className="form-control"
              />
            </div>
          </div>

          {/* Additional Setup */}
          <div className="mt-4">
            <SetUp />
          </div>

          {/* Submit Button */}
          <div className="text-center mt-0">
            <button className="btn btn-primary" id="calculate">Calculate</button>
          </div>
        </form>
              </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3">
          <div className="card shadow-sm p-4 mb-4">
            <h6 class="font-weight-bold text-verydark">Risk per trade:</h6>
            <form id="risk-form" onSubmit={setRisk}>
              <input 
                required 
                type='float' 
                placeholder={`${props.userAttrs.risk * 100} %`} 
                name="risk"
                value={riskValue}
                onClick= {clickHandler}
                className="form-control"
                style={{width: "60%", display: "inline", marginRight: "0.5em", marginBottom: "0.5em"}}
              />
              <button className="btn btn-danger">update</button>
            </form>
            {loader === true && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
          </div>
          <div class="card border-left-danger shadow py-2 mb-4">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                        Risk {props.userAttrs.risk*100}%</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">${riskBp[0].toFixed(2)}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
          <div class="card border-left-success shadow py-2 mb-2">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Buying Power $</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">${riskBp[1].toFixed(2)}</div>
                      </div>
                      <div class="col-auto">
                          <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>

      {/* Details Section */}
      {props.count > 0 && (
          <>
            <Plays />
          </>
      )}
    </div>
  </>
);
}

const mapStateToProps = state => {
  return {
    preReq: state.preReq,
    checkList: state.checkList,
    setUp: state.setUp,
    count: state.count,
    userAttrs: state.userAttrs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: data => {
      dispatch({ type: "SET_USER", payload: data });
    },
    setCount: data => {
      dispatch({ type: "SET_COUNT", payload: data });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);