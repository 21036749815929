import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showUser } from "../modules/auth";

const Pannel = props => {
  
  useEffect(() => {(async() => {
    if (props.userAttrs === null && props.savedTrades !== null) {
      let response = await showUser(props.savedTrades.user_id)
      response.status === 200 && props.setUser(response.data)
    }
  })()}, [props.userAttrs, props.savedTrades])
 
  return (
      <div>
        {props.userAttrs === null ? (
          <>
            <h2>Not Logged In</h2>
          </>
        ) : (
          <>
            {props.userAttrs.role === "subscriber" || props.userAttrs.role === 1 ? (
              <p>Subscribed</p> 
            ) : (
            <p>Limited Profile</p>
            )}
            <div class="collapse-divider"></div>

            {props.stats ? (
              <>
                <h8 class="collapse-item">Trades Won</h8>
                <h6 class="collapse-header">{props.stats['wins']}</h6>
                <h8 class="collapse-item">Trades Loss</h8>
                <h6 class="collapse-header">{props.stats['loss']}</h6>
                <h8 class="collapse-item">Profit/Loss Ratio</h8>
                <h6 class="collapse-header">{((props.stats['gains']/props.stats['negGains'])*-1).toFixed(2)}</h6>
                <h8 class="collapse-item">Average Win</h8>
                <h6 class="collapse-header">${(props.stats['gains']/props.stats['wins']).toFixed(2)}</h6>
                <h8 class="collapse-item">Average Loss</h8>
                <h6 class="collapse-header">${(props.stats['negGains']/props.stats['loss']).toFixed(2)}</h6>
              </>
            ):(
              <p>Loading statistics...</p>
            )}
            
            <div>
              <div>
                  {props.stats !== null && (
                    <div>
                      <h8 class="collapse-item">Largest Win</h8>
                      <h6 class="collapse-header">${props.stats['largestWin'].toFixed(2)}</h6>
                      <h8 class="collapse-item">Largest Loss</h8>
                      <h6 class="collapse-header">${props.stats['largestLoss'].toFixed(2)}</h6>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
  )
}

const mapStateToProps = state => {
  return {
    userAttrs: state.userAttrs,
    savedTrades: state.savedTrades,
    stats: state.stats
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: data => {
      dispatch({ type: "SET_USER", payload: data });
    },
    setStrategies: array => {
      dispatch({ type: "SET_STRATEGIES", payload: array });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Pannel);