import React from 'react';
import { HorizontalBar, Bar } from 'react-chartjs-2';
import { connect } from "react-redux";

const HourlyChart = props => {

  let totals = []
  let frequency = []
  let hourlyAvg = []
  let winAvg = []
  let lossAvg = []
  let accuracy = []
  if (props.times !== null) {
    for(let int in props.times) {
      totals.push((props.times[int][0]+props.times[int][2]).toFixed(2))
      frequency.push(props.times[int][1]+props.times[int][3])
      hourlyAvg.push(((props.times[int][0]+props.times[int][2])/(props.times[int][1]+props.times[int][3])).toFixed(2))
      winAvg.push((props.times[int][0]/props.times[int][1]).toFixed(2))
      lossAvg.push(((props.times[int][2]/props.times[int][3])*-1).toFixed(2))
      accuracy.push(((props.times[int][1]/(props.times[int][1]+props.times[int][3]))*100).toFixed(2))
    }
  }

  const timeIntervals = [
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00"
  ]

  const colours = totals.map((value) => value < 0 ? 'rgba(233, 133, 93, 0.719)' : 'rgba(75,192,192,0.4)');

  const hourlyTotals = {
    labels: timeIntervals,
    datasets: [
      {
        label: `${props.grossNet} Profit per Interval`,
        fill: true,
        backgroundColor: colours,
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: totals
      }
    ]
  };

  const hourlyFrequency = {
    labels: timeIntervals,
    datasets: [
      {
        label: 'Number of Trades',
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: frequency
      }
    ]
  };

  const hourlyAverage = {
    labels: timeIntervals,
    datasets: [
      {
        label: 'Average PnL/trade',
        backgroundColor: colours,
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: hourlyAvg
      }
    ]
  };

  const winLossAverage = {
    labels: timeIntervals,
    datasets: [
      {
        label: 'Average Winner',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: winAvg
      },
      {
        label: 'Average Loser',
        backgroundColor: 'rgba(233, 133, 93, 0.719)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: lossAvg
      }
    ]
  };

  const hourlyAccuracy = {
    labels: timeIntervals,
    datasets: [
      {
        label: 'Win Accuracy %',
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        hoverBackgroundColor: 'rgba(75,192,192)',
        data: accuracy
      }
    ]
  };

  const barOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'month'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 8,
           // Include a dollar sign in the ticks
           callback: function (value) {
            return '$' + new Intl.NumberFormat().format(value);
          },
        },
        maxBarThickness: 25
      }],
      yAxes: [{
        ticks: {
          min: 0,
          maxTicksLimit: 5,
          padding: 10
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
    },
  }

  const barOptionsNoDollar = {
    ...barOptions,
    scales: {
      ...barOptions.scales,
      xAxes: [
        {
          ...barOptions.scales.xAxes[0],
          ticks: {
            ...barOptions.scales.xAxes[0].ticks,
            callback: function (value) {
              return value; // No dollar sign formatting
            },
          },
        },
      ],
    },
  };

  return (
    <div className='row'>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Profit per interval</h6>
            </div>
            <div class="card-body">
                <div>
                  <HorizontalBar
                    data = {hourlyTotals}
                    options = {barOptions}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> + Gains
                  </span>
                  <span class="mr-2">
                      <i class="fas fa-circle red"></i> - Gains
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Frequency per hour </h6>
            </div>
            <div class="card-body">
                <div>
                  <HorizontalBar
                    data = {hourlyFrequency}
                    options = {barOptionsNoDollar}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> Numerical Value
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">PnL Average</h6>
            </div>
            <div class="card-body">
                <div>
                  <HorizontalBar
                    data = {hourlyAverage}
                    options = {barOptions}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> + $
                  </span>
                  <span class="mr-2">
                      <i class="fas fa-circle red"></i> - $
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Avg Winner & Avg Loser</h6>
            </div>
            <div class="card-body">
                <div>
                  <HorizontalBar
                    data = {winLossAverage}
                    options = {barOptions}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> $
                  </span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6">
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-verydark">Accuracy</h6>
            </div>
            <div class="card-body">
                <div>
                  <Bar
                    data = {hourlyAccuracy}
                    options = {barOptionsNoDollar}
                  />
                </div>
                <div class="mt-4 text-center small">
                  <span class="mr-2">
                      <i class="fas fa-circle green"></i> %
                  </span>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    savedTrades: state.savedTrades
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSavedTrades: data => {
      dispatch({ type: "SET_SAVEDTRADES", payload: data });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HourlyChart)